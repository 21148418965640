<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <div class="container">
        <div class="card card-custom">
          <!--begin::Create-->
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ pageTitle }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-start px-8 px-lg-10">
              <div class="col-12 justify-content-start mb-5">
                <!--begin::Form Wizard-->
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form class="form">
                    <!--begin::Step 1-->
                    <div
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <div class="row">
                        <div class="col-xl-12">
                          <div
                            class="border-bottom border-bottom-dark mb-10 pb-5"
                            v-if="fromDetailModal"
                          >
                            <b>{{
                              $t("merchant.details_transaction_modaltitle")
                            }}</b>
                            <div class="row mt-5">
                              <div class="row col-md-4 col-sm-12">
                                <p class="col-8">
                                  {{ $t("merchant.details_bs_transaction_type") }}
                                </p>
                                <b class="col-4">{{
                                  outPurchaseStorageData.typeStr
                                }}</b>
                              </div>
                              <div class="row col-md-4 col-sm-12">
                                <p class="col-8">
                                  {{ $t("merchant.details_bs_transaction_amount") }}
                                </p>
                                <b class="col-4">{{
                                  outPurchaseStorageData.amountFormatted
                                }}</b>
                              </div>
                              <div class="row col-md-4 col-sm-12">
                                <p class="col-8">
                                  {{ $t("merchant.details_bs_transaction_awr") }}
                                </p>
                                <b class="col-4">{{
                                  outPurchaseStorageData.buySellFromOutside
                                }}</b>
                              </div>
                            </div>
                          </div>
                          <div class="form-group" v-if="!fromDetailModal">
                            <label class="col-form-label">{{
                              $t("merchant.buySell")
                            }}</label>
                            <validation-provider
                              rules="required"
                              :name="$t('merchant.buySell')"
                              v-slot="{ classes, errors }"
                            >
                              <multiselect
                                v-model="selectedType"
                                :options="types"
                                :internal-search="true"
                                @input="onChangeType"
                                :allow-empty="false"
                                open-direction="bottom"
                                deselect-label=""
                                select-label=""
                                selected-label=""
                                track-by="label"
                                label="label"
                                :placeholder="$t('merchant.buySell')"
                                :class="classes"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  >{{ option.label }}</template
                                >
                                <template slot="option" slot-scope="{ option }">{{
                                  option.label
                                }}</template>
                                <template slot="noOptions">{{
                                  "List is empty"
                                }}</template>
                              </multiselect>
                              <span class="error__message">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>

                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("merchant.provider")
                            }}</label>
                            <validation-provider
                              rules="required"
                              :name="$t('merchant.provider')"
                              v-slot="{ errors, classes }"
                            >
                              <multiselect
                                v-model="selectedProvider"
                                :options="providers"
                                :internal-search="true"
                                @input="onChangeProvider"
                                :allow-empty="false"
                                open-direction="bottom"
                                deselect-label=""
                                select-label=""
                                selected-label=""
                                track-by="label"
                                label="label"
                                :show-labels="false"
                                :placeholder="$t('merchant.provider')"
                                :class="classes"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  >{{ option.label }}</template
                                >
                                <template slot="option" slot-scope="{ option }">{{
                                  option.label
                                }}</template>
                                <template slot="noOptions">{{
                                  "List is empty"
                                }}</template>
                              </multiselect>
                              <span class="error__message">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>

                          <div class="form-group" v-if="!fromDetailModal">
                            <label class="col-form-label">{{
                              $t("merchant.kind")
                            }}</label>
                            <validation-provider
                              rules="required"
                              :name="$t('merchant.kind')"
                              v-slot="{ classes, errors }"
                            >
                              <multiselect
                                v-model="selectedCrypto"
                                :options="cryptos"
                                :internal-search="true"
                                :allow-empty="false"
                                open-direction="bottom"
                                deselect-label=""
                                select-label=""
                                selected-label=""
                                track-by="name"
                                label="name"
                                :placeholder="$t('merchant.kind')"
                                :class="classes"
                                @input="onChangeKind"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  >{{ option.name }}</template
                                >
                                <template slot="option" slot-scope="{ option }">{{
                                  option.name
                                }}</template>
                                <template slot="noOptions">{{
                                  "List is empty"
                                }}</template>
                              </multiselect>
                              <span class="error__message">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>

                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("finance.amount")
                            }}</label>
                            <validation-provider
                              rules="required"
                              :name="$t('finance.amount')"
                              v-slot="{ classes, errors }"
                              v-if="!fromDetailModal"
                            >
                              <currency-input
                                class="form-control"
                                :placeholder="$t('finance.amount')"
                                :class="classes"
                                :precision="amountPrecision"
                                v-model="amount"
                              />
                              <span class="error__message">{{ errors[0] }}</span>
                            </validation-provider>
                            <input
                                v-else
                                disabled
                                class="form-control"
                                :value="outPurchaseStorageData.cryptobankAmount"
                              />
                          </div>

                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("finance.fee")
                            }}</label>
                            <validation-provider
                              rules="required"
                              :name="$t('finance.fee')"
                              v-slot="{ classes, errors }"
                            >
                              <input
                                :class="classes"
                                type="number"
                                placeholder="0.00"
                                step="0.01"
                                min="0.00"
                                autocomplete="off"
                                class="form-control"
                                v-model="fee"
                              />
                              <span class="error__message">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>

                          <div class="form-group">
                            <label class="col-form-label">{{
                              $t("commons.price")
                            }}</label>
                            <validation-provider
                              rules="required"
                              :name="$t('commons.price')"
                              v-slot="{ classes, errors }"
                            >
                              <currency-input
                                class="form-control"
                                :placeholder="$t('commons.price')"
                                :class="classes"
                                :precision="pricePrecision"
                                v-model="price"
                              />
                              <span class="error__message">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-center pt-10">
                      <form-submit-btn
                        :btnClass="'btn btn-pill btn-success w-75'"
                        :isSubmitting="isSubmitting"
                        @click="handleSubmit(_handleSubmit)"
                        :text="$t('commons.save')"
                      />
                    </div>
                    <!--end::Actions-->
                  </form>
                </ValidationObserver>
                <!--end::Form Wizard-->
              </div>
            </div>
          </div>
          <!--end::Create-->
        </div>
      </div>
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import "vue-multiselect/dist/vue-multiselect.min.css";
import i18n from '@/config/_i18n'

const buySellTypes = [
  { value: "10", label: i18n.t("merchant.buy") },
  { value: "20", label: i18n.t("merchant.sell") },
];
const providers = [{ value: "nexus", label: "Nexus" }];

export default {
  name: "Withdraw",
  data() {
    return {
      selectedType: buySellTypes[0],
      types: buySellTypes,
      selectedProvider: providers[0],
      providers: providers,
      selectedCrypto: null,
      amount: null,
      fee: null,
      price: null,
      amountPrecision: 8,
      pricePrecision: 3,
      isSubmitting: false,
    };
  },
  components: {
    Multiselect,
    Breadcrumb
  },
  computed: {
    ...mapState({
      cryptos: (state) => state.transactions.outPurchasesCreateData.currencies,
      outPurchaseAmount: (state) => state.transactions.outPurchasesCreateData.amount,
      model: state => state.customerManagement.buyFromOutPurchaseData.model,
      model_id: state => state.customerManagement.buyFromOutPurchaseData.model_id,
    }),

    outPurchaseStorageData() {
      return JSON.parse(localStorage.getItem("outsidePurchase"));
    },
    fromDetailModal() {
      return this.outPurchaseStorageData?.fromDetailModal || false;
    },
    pageTitle() {
      return this.fromDetailModal ? 
        this.$t("merchant.details_bsbtn_modal_title") :
        this.$t("merchant.adding_new_import_transaction_title");
    },
    breadcrumb_data() {
      return {
        title: this.$t('merchant.title'),
        subtitle: [
          { title: this.$t('merchant.all_transactions'), route: '/merchant/transactions' },
          this.pageTitle
        ]
      }
    }
  },
  methods: {
    ...mapActions("transactions", ["GET_OUTPURCHASES_CREATE_DATA", "POST_OUTPURCHASES"]),
    async _handleSubmit() {
      // crmv1/financecrm/out-purchases/one/create
      const formData = {
        quick_sale_type: this.fromDetailModal
          ? this.outPurchaseStorageData.type
          : this.selectedType.value,
        provider: this.selectedProvider.value,
        currency_id: this.fromDetailModal
          ? this.outPurchaseStorageData.currencyId
          : this.selectedCrypto.id,
        amount: this.amount,
        fee: this.fee,
        price: this.price,
        model: this.model,
        model_id: this.model_id
      };
      this.isSubmitting = true;
      try {
        await this.POST_OUTPURCHASES(formData)
        localStorage.removeItem("outsidePurchase");
        this.$router.push({ name: 'TransactionsList' })
      } catch (error) {
        console.log(error);
      } finally {
        this.isSubmitting = false
      }
    },
    onChangeType() {},
    onChangeKind() {},
    onChangeProvider() {},
  },
  created() {
    if(!this.outPurchaseStorageData) {
        this.$router.push({ name: 'TransactionsList' });
        return
    }
    const {
      type,
      currencyCode: coin_code,
      currencyId: coin_id,
      cryptobankAmount
    } = this.outPurchaseStorageData;
    this.GET_OUTPURCHASES_CREATE_DATA({ type, coin_code, coin_id })
      .then(() => {
        if (!this.fromDetailModal) {
          this.amount = this.outPurchaseAmount;
          this.selectedCrypto = this.cryptos.find(
            (val) => val.id.toString() === coin_id.toString()
          );
        } else {
            this.amount = cryptobankAmount
        }
      })
      .catch(() => {});
      if(this.fromDetailModal) this.$store.dispatch('customerManagement/TRADING_OUT_PURCHASE_CRYPTOBANKS_CREATE', this.$route.query.id)
  },
};
</script>
